<div class="fundraising_team_container">
  <div style="width: 100%">
    <div class="header_team">
      Fundraising Teams ({{ fundraiserTeams?.length }})
    </div>
    <div class="team_list">
      <div
        class="teams cursor"
        title="Select"
        *ngFor="let team of fundraiserTeams; let i = index"
        (click)="selectTeam(team)"
        [ngClass]="{ 'selected-team': team === selectedTeam }"
      >
        <div style="margin: 4px">
          <div class="top_part">
            <div class="details_div">
              <div class="font_18" style="font-weight: 700">
                {{ i + 1 }}
              </div>

              <div class="team_icon_div">
                <img
                  class="team_icon_img"
                  [src]="
                    team?.team_logo
                      ? team?.team_logo?.url
                      : 'assets/images/donate_securely.png'
                  "
                />
              </div>
              <div class="info">
                <div class="font_18" style="font-weight: 700">
                  {{ team?.team_name }}
                </div>
                <div class="font_18" style="color: #808080">
                  {{ team.members?.length }} Member{{
                    team.members?.length > 1 ? "s" : ""
                  }}
                </div>
              </div>
            </div>
            <img
              loading="lazy"
              src="../../../../../assets/images/icon/chevron_right.png"
              alt="Chevron"
              style="height: 24px; width: 24px"
              title="View members"
            />
          </div>
          <div class="bottam_part">
            <dx-progress-bar
              class="progress_team"
              [min]="0"
              [max]="team.fundraising_target"
              [value]="team.fund_raised"
              [showStatus]="false"
            >
            </dx-progress-bar>
            <div class="bottom_part">
              <span class="fw-bold">৳{{ team.fund_raised | number }}</span>
              <span class="fw-normal">
                raised of ৳{{ team.fundraising_target | number }}
                goal
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Members List -->
  <div style="width: 100%">
    <div class="header_team">
      Members ({{ selectedTeam?.members ? selectedTeam?.members.length : 0 }})
    </div>
    <div class="team_list">
      <div
        class="teams"
        *ngFor="let member of selectedTeam?.members; let j = index"
      >
        <div style="margin: 4px">
          <div class="top_part">
            <div class="details_div">
              <div class="font_18" style="font-weight: 700">
                {{ j + 1 }}
              </div>

              <div class="team_icon_div">
                <img
                  class="team_icon_img"
                  [src]="
                    member.profile_img
                      ? member.profile_img?.url
                      : 'assets/images/round_profile.png'
                  "
                />
              </div>
              <div class="info">
                <div class="font_18" style="font-weight: 700">
                  {{ member.fundraiser_name }}
                </div>
                <div class="font_18" style="color: #808080">
                  {{ selectedTeam?.team_name }}
                </div>
              </div>
            </div>
          </div>
          <div class="bottam_part">
            <div class="bottom_part">
              <span class="fw-bold">৳{{ member.fund_raised | number }}</span>
              <span class="fw-normal">
                raised of ৳{{ member.fundraising_target | number }}
                goal
              </span>
            </div>
            <dx-progress-bar
              class="progress_team"
              [min]="0"
              [max]="member.fundraising_target"
              [value]="member.fund_raised"
              [showStatus]="false"
            >
            </dx-progress-bar>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-2">
  <div *ngIf="selectedTeam" class="font_18 mt-3" style="margin: 4px">
    <div class="top_part">
      <div class="details_div">
        <div class="team_logo_big">
          <img
            class="team_icon_img"
            [src]="
              selectedTeam?.team_logo
                ? selectedTeam?.team_logo?.url
                : 'assets/images/donate_securely.png'
            "
          />
        </div>
        <div class="info">
          <div class="font_24" style="font-weight: 700">
            {{ selectedTeam?.team_name }}
          </div>
          <div class="font_18" style="color: #808080">
            Fundraiser since {{ selectedTeam?.created_at | date : "MMM, yyyy" }}
          </div>
        </div>
      </div>
    </div>
    <div class="bottam_part">
      <div class="bottom_part">
        <div>
          <span class="font_18 fw-bold">
            ৳{{ selectedTeam?.fund_raised | number }}
          </span>
          <span class="font_14 mx-3">
            {{ selectedTeam?.members?.length }} Member{{
              selectedTeam?.members?.length > 1 ? "s" : ""
            }}</span
          >
        </div>
        <span class="font_14 fw-normal">
          {{
            (selectedTeam?.fund_raised / selectedTeam?.fundraising_target) * 100
              | number : "1.0-0"
          }}% raised of ৳{{ selectedTeam?.fundraising_target | number }}
          goal
        </span>
      </div>
      <dx-progress-bar
        class="progress_team"
        [min]="0"
        [max]="selectedTeam?.fundraising_target"
        [value]="selectedTeam?.fund_raised"
        [showStatus]="false"
      >
      </dx-progress-bar>
    </div>
    <div class="font_18 d-flex flex-column gap-3 mt-3">
      <div class="fw-bold">Story of {{ selectedTeam?.team_name }}</div>
      <div style="text-align: justify">
        {{ selectedTeam?.story }}
      </div>
    </div>
  </div>
</div>
