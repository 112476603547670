<dx-popup
  [(visible)]="showSignUpPopup"
  [width]="721"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="sign_up_div">
    <div class="sign_up_file_input_div">
      <div class="auth_title">Join Fundraiser Team</div>
      <div class="auth_description">
        Please complete the donation form, and our team will get in touch with
        you shortly.
      </div>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Your Name</label>
      <dx-text-box
        class="input_field"
        [(value)]="signUpModel.name"
        placeholder="Enter your name here"
        [showClearButton]="true"
      >
        <dx-validator validationGroup="signUpValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Name is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Email</label>
      <dx-text-box
        class="input_field"
        [(value)]="signUpModel.email"
        placeholder="Enter your email address here"
        [showClearButton]="true"
      >
        <dx-validator validationGroup="signUpValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Email is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Password</label>
      <dx-text-box
        class="input_field"
        [(value)]="signUpModel.password"
        placeholder="Enter your password"
        [showClearButton]="true"
        [mode]="isPasswordVisible ? 'text' : 'password'"
        [inputAttr]="{ 'aria-label': 'Password' }"
        [buttons]="[
          {
            name: 'togglePassword',
            location: 'after',
            options: {
              icon: 'eye',
              onClick: togglePasswordVisibility,
              elementAttr: {
                class: 'password-toggle ' + (isPasswordVisible ? 'visible' : '')
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="signUpValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Password is required"
          ></dxi-validation-rule>
          <dxi-validation-rule
            *ngIf="signUpModel.password != confirmPassword"
            type="required"
            message="Passwords doesn't match"
          >
          </dxi-validation-rule>
          <dxi-validation-rule
            type="pattern"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            message="Passwords must be at least 8 characters long and contain a mix of upper and lower case letters, numbers, and special characters(@$!%*?&)."
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Confirm Password</label>
      <dx-text-box
        class="input_field"
        [(value)]="confirmPassword"
        placeholder="Enter your password"
        [showClearButton]="true"
        [mode]="isConfirmPasswordVisible ? 'text' : 'password'"
        [inputAttr]="{ 'aria-label': 'Password' }"
        [buttons]="[
          {
            name: 'togglePassword',
            location: 'after',
            options: {
              icon: 'eye',
              onClick: toggleConfirmPasswordVisibility,
              elementAttr: {
                class:
                  'password-toggle ' +
                  (isConfirmPasswordVisible ? 'visible' : '')
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="signUpValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Confirm your password"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="compare"
            [comparisonTarget]="passwordComparison"
            message="Password and Confirm Password do not match"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div class="w-100 mt-3">
      <button class="blue-button-md w-100" (click)="signUp()">Submit</button>
    </div>
    <div class="terms_and_conditions">
      <span>By proceeding further you agree to our </span>
      <span class="url">Terms and conditions</span>
      <span> and </span>
      <span class="url">Privacy Policy</span>
    </div>
    <div class="log_in_line">
      <span>Already have an account?</span>
      <span class="log_in cursor" (click)="toggleSignupLogin('login')">
        Log in</span
      >
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="showOtpPopUp"
  [width]="721"
  [showCloseButton]="false"
  [showTitle]="false"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="sign_up_div">
    <div class="sign_up_file_input_div">
      <div class="auth_title">Verify your email address</div>
      <div class="auth_description">
        We’ve sent a verification code to your email. Please enter the code
        below to verify your email address.
      </div>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Verification Code</label>
      <dx-text-box
        class="input_field"
        [(value)]="otpModel.otp"
        placeholder="Enter the 6-digit code"
        [showClearButton]="true"
        [buttons]="[
          {
            name: 'lock',
            location: 'before',
            options: {
              icon: './assets/images/icon/lock_icon_black.svg',
              elementAttr: {
                style: 'border:none'
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule
            type="required"
            message="OTP is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <label *ngIf="disableResendOtp" class="timer_label"
        >Resend Code in <span style="color: #0054a6">{{ timer }}</span></label
      >
    </div>
    <div class="w-100 mt-3">
      <button class="blue-button-md w-100" (click)="verifyOtp()">
        Continue
      </button>
    </div>
    <div class="d-flex justify-content-center">
      <button
        *ngIf="!disableResendOtp"
        class="white-button-md"
        style="border: none"
        (click)="resendOtp()"
      >
        Resend code
      </button>
      <div *ngIf="disableResendOtp" class="font_16">Resend code</div>
    </div>
    <div class="terms_and_conditions">
      <span
        >Didn’t receive the email? Check your spam folder or click "Resend Code"
        to get a new one.</span
      >
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="showLoginPopup"
  [width]="721"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="sign_up_div">
    <div class="sign_up_file_input_div">
      <div class="auth_title">Sign In For Fundraising</div>
      <div class="auth_description">Login to your Shohay account</div>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Email</label>
      <dx-text-box
        class="input_field"
        [(value)]="loginModel.email"
        placeholder="Enter your email address here"
        [showClearButton]="true"
      >
        <dx-validator validationGroup="loginValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Email is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Password</label>
      <dx-text-box
        class="input_field"
        [(value)]="loginModel.password"
        placeholder="Enter your password"
        [showClearButton]="true"
        [mode]="isPasswordVisible ? 'text' : 'password'"
        [inputAttr]="{ 'aria-label': 'Password' }"
        [buttons]="[
          {
            name: 'togglePassword',
            location: 'after',
            options: {
              icon: 'eye',
              onClick: togglePasswordVisibility,
              elementAttr: {
                class: 'password-toggle ' + (isPasswordVisible ? 'visible' : '')
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="loginValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Password is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div class="w-100 mt-3">
      <button class="blue-button-md w-100" (click)="login()">Submit</button>
    </div>
    <div class="terms_and_conditions">
      <span>By proceeding further you agree to our </span>
      <span class="url">Terms and conditions</span>
      <span> and </span>
      <span class="url">Privacy Policy</span>
    </div>
    <div class="log_in_line">
      <span>Don’t have an account?</span>
      <span class="log_in cursor" (click)="toggleSignupLogin('signup')">
        Sign Up</span
      >
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="showFundraiserPopUp"
  [width]="721"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="sign_up_div">
    <div class="sign_up_file_input_div">
      <div class="auth_title">Fundraise</div>
      <div class="auth_description">
        <span class="font_16 c_0054a6">Sign UP ></span> Create Page
        <span class="font_16 c_0054a6">> Share</span>
      </div>
      <div class="d-flex gap-3 justify-content-between w-100">
        <div
          [ngClass]="
            fundraiserFormTab === 1 ? 'active_tab active' : 'active_tab'
          "
          (click)="toggleFundraiserFormTab(1)"
        >
          Join an existing team
        </div>
        <div
          [ngClass]="
            fundraiserFormTab === 2 ? 'active_tab active' : 'active_tab'
          "
          (click)="toggleFundraiserFormTab(2)"
        >
          Create own Team
        </div>
      </div>
    </div>
    <div class="input_div mt-3">
      <label class="field_label requiredMark"
        >Which team would you like to join?</label
      >
      <dx-select-box
        *ngIf="fundraiserFormTab === 1"
        class="input_field"
        [dataSource]="fundraiserTeams"
        [(value)]="selectedFundraiserTeam"
        placeholder="Select ..."
        [showClearButton]="true"
        displayExpr="team_name"
      >
        <dx-validator validationGroup="fundraiserValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Team is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
      <dx-text-box
        *ngIf="fundraiserFormTab === 2"
        class="input_field"
        [(value)]="fundraiserTeamModel.team_name"
        placeholder="Enter your team name here"
        [showClearButton]="true"
      >
        <dx-validator validationGroup="fundraiserValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Team is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>

    <div *ngIf="fundraiserFormTab === 2" class="sign_up_file_input_div">
      <div
        class="profile_pic"
        title="Add Image"
        (click)="triggerFileInput('teamLogo')"
      >
        <img *ngIf="!teamLogoUrl" src="./assets/images/icon/upload_icon.svg" />
        <img class="profile_image" *ngIf="teamLogoUrl" [src]="teamLogoUrl" />
        <input
          #teamLogo
          type="file"
          class="d-none"
          id="teamLogo"
          (change)="onFileChange($event, 'teamLogo')"
          aria-hidden="true"
          accept=".jpg, .jpeg, .png"
        />
      </div>
      <div class="log_in" style="color: black">Team Logo</div>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark"
        >What is your fundraising goal?</label
      >
      <dx-number-box
        class="input_field"
        [(value)]="fundraiserTeamModel.fundraising_target"
        placeholder="৳ 20000"
        [showClearButton]="true"
        format="৳ #,##0"
        [invalidValueMessage]="'Value must be a number'"
      >
        <dx-validator validationGroup="fundraiserValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Goal is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-number-box>
    </div>
    <div class="input_div">
      <label class="field_label requiredMark">Tell us about your story?</label>
      <dx-text-box
        class="input_field"
        [(value)]="fundraiserTeamModel.story"
        placeholder="Tell your story..."
      >
        <dx-validator validationGroup="fundraiserValidationGrp">
          <dxi-validation-rule
            type="required"
            message="Story is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
    <div class="w-100 mt-3 mb-3">
      <button
        class="blue-button-md w-100"
        (click)="
          fundraiserFormTab === 1
            ? joinOrCreateTeam('join')
            : joinOrCreateTeam('create')
        "
      >
        {{
          fundraiserFormTab === 1 ? "Join this fundraiser" : "Create your team"
        }}
      </button>
    </div>
  </div>
</dx-popup>

<dx-popup
  [(visible)]="showLastPopUp"
  [width]="672"
  [height]="327"
  [showCloseButton]="true"
  [showTitle]="true"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
>
  <div class="sign_up_div justify-content-between">
    <div class="auth_title">Join as a Fundraiser</div>
    <div class="d-flex gap-3 align-items-center">
      <div>
        <img
          width="61"
          style="border-radius: 50%"
          [src]="
            sessionUser?.profile_pic
              ? sessionUser?.profile_pic
              : 'assets/images/round_profile.png'
          "
        />
      </div>
      <div>
        <p class="font_24">{{ sessionUser?.name }}</p>
        <p class="font_18">Signed in as {{ sessionUser?.email }}</p>
      </div>
    </div>
    <div class="w-100 mt-3 mb-3 d-flex gap-3">
      <button
        class="yellow-button-md w-50"
        (click)="toggleFundraiserPopup(false)"
      >
        Cancel
      </button>
      <button class="blue-button-md w-50" (click)="toggleFundraiserPopup(true)">
        Continue
      </button>
    </div>
  </div>
</dx-popup>
