import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable } from 'rxjs';
import {
  ApprovalCategoryEnum,
  ApprovalStatusEnum,
} from '../../enum/approval-category.enum';
import {
  ApprovalModel,
  ApprovalUpdateModel,
  CampaignFeaturedAttributeModel,
} from '../../model/approval.model';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ApprovalService {
  private approvalUrl = `${UrlEnum.SHOHAY_NGO}/super-admin/approval`;
  private approvalCategoryUrl = `${UrlEnum.SHOHAY_NGO}/super-admin/approval-category`;

  public static approval_category = new Map<ApprovalCategoryEnum, string>();

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {
    this.getAllApprovalCategory();
  }

  private getAllApprovalCategory(): void {
    this.httpRequestService
      .get(`${this.approvalCategoryUrl}/get-all`)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => {
          const map = new Map<ApprovalCategoryEnum, string>();
          x.forEach((cat: any) => map.set(cat.category_key, cat.id));
          return map;
        })
      )
      .subscribe((map: Map<ApprovalCategoryEnum, string>) => {
        ApprovalService.approval_category = map;
      });
  }

  public getAllRequest(category: ApprovalCategoryEnum): Observable<any> {
    return this.httpRequestService.get(`${this.approvalUrl}/get-all`).pipe(
      map((x: any) => this.utilitiesService.responseHandler(x)),
      map((x: any) => {
        return x
          .filter((item: any) => item.approval_category === category)
          .sort((a: any, b: any) => {
            return (
              new Date(b.updated_at).getTime() -
              new Date(a.updated_at).getTime()
            );
          })
          .map((item: any) => {
            item.attribute = item.attribute
              ? this.utilitiesService.jsonParse(item.attribute)
              : null;
            item.attribute.logo_url = item.attribute?.logo_url
              ? this.utilitiesService.jsonParse(item.attribute?.logo_url)
              : null;
            item.searchValue =
              item.attribute?.title +
              '_' +
              item.attribute?.subtitle +
              '_' +
              item.attribute?.ngo_name +
              '_' +
              item.status +
              '_' +
              item.attribute;
            return item;
          });
      })
    );
  }

  public makeRequest(body: ApprovalModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.approvalUrl}/create`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public takeApprovalAction(
    id: string,
    body: ApprovalUpdateModel
  ): Observable<any> {
    return this.httpRequestService
      .patch(`${this.approvalUrl}/update/${id}`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getApprovalBody(
    source: any,
    category: ApprovalCategoryEnum
  ): ApprovalModel {
    const user: any = LocalStorageService.getSessionUserStatic();
    const body: ApprovalModel = new ApprovalModel(category);

    body.ngo_id = source.ngo_id;
    body.project_id = source.project_id;
    body.approval_requested_by = user?.id;
    body.approval_category_id = source.id;
    body.attribute = JSON.stringify(new CampaignFeaturedAttributeModel(source));

    return body;
  }
}
