<div class="top_div">
  <div class="main_div">
    @if(!popup){
      <div class="title_full_div">
        <div class="title_div">
          <div class="title_privacy">Terms of Service</div>
          <div class="sub_title">Clear Terms, Fair Conditions, Mutual Trust.</div>
        </div>
      </div>
    }
    <div class="white_div_container">
      <div class="white_div">
        <div class="policy_1">
          <div class="title">
            <span class="last-update">Last Updated:</span> October 24, 2024
          </div>
          <div class="description">
            Welcome to Shohay! These Terms of Service (“Terms”) govern your
            access to and use of the Shohay website, mobile application, and all
            related services (collectively, the “Platform”). By accessing or
            using the Platform, you agree to these Terms. If you do not agree,
            you may not use the Platform.
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Definitions</div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights">Shohay:</span> Refers to the
                organization that operates the Platform.
              </li>
              <li>
                <span class="highlights">Platform: </span> Refers to the Shohay
                website, mobile application, and all related services.
              </li>
              <li>
                <span class="highlights">User: </span> Any individual or
                organization that accesses or uses the Platform.
              </li>
              <li>
                <span class="highlights">NGO: </span> Non-governmental
                organizations registered or managing profiles on the Platform.
              </li>
              <li>
                <span class="highlights">Non-Profit: </span> Not-for-profit
                organizations registered or managing profiles on the Platform.
              </li>
              <li>
                <span class="highlights">Donor: </span> Any individual or
                organization that makes donations through the Platform.
              </li>
            </ul>
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">User Accounts</div>
          <div class="definition_sub">
            To use certain features of Shohay, you need to create an account. By
            creating an account, you agree to:
          </div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights">Eligibility:</span> You must be at
                least 18 years old to create an account.
              </li>
              <li>
                <span class="highlights">Account Responsibility: </span> You are
                responsible for maintaining the confidentiality of your account
                credentials and for any activities that occur under your
                account. Notify us immediately of any unauthorized access or
                breach.
              </li>
              <li>
                <span class="highlights">Accurate Information: </span> You agree
                to provide accurate, complete, and up-to-date information when
                creating an account, and to promptly update any changes.
              </li>
              <li>
                <span class="highlights">Account Suspension: </span> Shohay
                reserves the right to suspend or terminate your account at any
                time, for any reason, including but not limited to violations of
                these Terms or misuse of the Platform.
              </li>
            </ul>
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">NGO or Non-Profit Profiles</div>
          <div class="definition_sub">
            NGOs or non-profits registered on Shohay are responsible for
            maintaining accurate and up-to-date profiles:
          </div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights">Accurate Information: </span> NGOs and
                non-profits must provide accurate and complete information about
                their organization.
              </li>
              <li>
                <span class="highlights">Verification: </span>Shohay can request
                supporting documentation to verify an NGO or Non-profit’s
                registration status.
              </li>
              <li>
                <span class="highlights">Content Responsibility: </span> NGOs
                and Non-profits are solely responsible for the content they
                publish on their profiles. Shohay is not liable for the accuracy
                or completeness of this information.
              </li>
            </ul>
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Donations</div>
          <div class="definition_sub">
            Shohay provides a secure platform for donors to support NGOs and
            Non-profits. By making a donation, you agree to the following terms:
          </div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights">Secure Processing: </span> Shohay uses
                secure third-party payment gateways to process all donations
              </li>
              <li>
                <span class="highlights">Donation Confirmation: </span> You will
                receive an email confirmation of your donation.
              </li>
              <li>
                <span class="highlights">Refunds: </span> Shohay’s refund policy
                will be clearly stated on the Platform and may vary depending on
                the NGO or specific campaign.
              </li>
              <li>
                <span class="highlights">Tax Deductibility:</span>Information on
                the tax deductibility of donations will be provided based on
                applicable regulations in the target country, such as,
                Bangladesh. It is your responsibility to verify any tax-related
                benefits.
              </li>
            </ul>
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Prohibited Activities</div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights"> Violating Laws: </span>: You may not
                use the Platform for any unlawful purpose or in violation of
                applicable laws and regulations.
              </li>
              <li>
                <span class="highlights"> Infringing on Rights: </span> You may
                not infringe on the rights of others, including intellectual
                property and privacy rights.
              </li>
              <li>
                <span class="highlights">Posting Harmful Content: </span> You
                may not post or share any offensive, harmful, or inappropriate
                content on the Platform.
              </li>
              <li>
                <span class="highlights">Disrupting the Platform: </span> You
                may not interfere with or disrupt the functionality of the
                Platform, servers, or networks connected to the Platform.
              </li>
              <li>
                <span class="highlights"
                  >Spam or Unsolicited Communications:
                </span>
                You may not use the Platform to distribute spam, advertisements,
                or other unsolicited communications.
              </li>
            </ul>
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Intellectual Property</div>
          <div class="terms">
            The Platform and all original content, including text, graphics,
            logos, and software, are owned by Shohay and protected by
            international copyright, trademark, and other intellectual property
            laws. You are not authorized to use Shohay’s content without
            permission.
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Disclaimer of Warranties</div>
          <div class="terms">
            The Platform is provided “as is,” without any warranties, express or
            implied. Shohay does not guarantee that the Platform will be
            uninterrupted, error-free, or free of harmful components.
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Limitation of Liability</div>
          <div class="terms">
            To the fullest extent permitted by law, Shohay is not liable for any
            damages resulting from your use of the Platform, including direct,
            indirect, incidental, or consequential damages.
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Indemnification</div>
          <div class="terms">
            You agree to indemnify and hold Shohay harmless from any claims,
            damages, liabilities, and expenses (including legal fees) arising
            out of your use of the Platform or your breach of these Terms.
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Governing Law</div>
          <div class="terms">
            These Terms are governed by and construed in accordance with the
            laws of the donor country and the country where the recipient NGO or
            Non-profit is located, such as, Bangladesh, without regard to its
            conflict of law principles. Any disputes arising from these Terms
            will be resolved in the courts of the target country.
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Automated Decision-Making and Profiling</div>
          <div class="terms">
            Shohay does not engage in automated decision-making or profiling
            that would produce legal or significant effects on users. Should
            this change, we will update this policy to inform you of your rights
            in relation to such processing.
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Changes to These Terms</div>
          <div class="terms">
            Shohay reserves the right to update or modify these Terms at any
            time. We will notify users of any significant changes by posting the
            updated Terms on the Platform. Your continued use of the Platform
            following the posting of changes means you accept and agree to the
            updated Terms.
          </div>
        </div>

        <div class="policy_1">
          <div class="definiton">Contact Us</div>
          <div class="description_">
            <div class="description">
              If you have any questions or concerns regarding this Privacy
              Policy, please feel free to reach out to us:
            </div>
            <div>
              <span class="highlights_ block">Shohay Support Team</span>
              <div>
                <span class="highlights_">Email: </span>
                <span class="shohay_email">support&#64;shohay.net</span>
              </div>
              <span class="highlights_">Address:</span> 53 Purana Paltan, Dhaka,
              Bangladesh
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
