import { Injectable } from '@angular/core';
// import { exportDataGrid as exportDataGridToXLSX } from 'devextreme/excel_exporter';
// import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter';
// import { Workbook } from 'exceljs';
// import { saveAs } from 'file-saver-es';
// import { jsPDF } from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
// import { HttpRequestSuccessFailed } from '../../enum/http-request-success-failed.enum';
// import { IErrorResponse } from '../../interface/error-response.interface';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpRequestService } from '../http-request/http-request.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  isLoginRequest: boolean = false;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly http: HttpRequestService,
    private readonly spinner: NgxSpinnerService,
    public readonly localStorageService: LocalStorageService
  ) {}

  public sheetTemplateURL = './assets/sheetTemplate/';
  public rowSize = 10;
  public allowedPageSizes = [10, 20, 50, 100, 250, 500, 1000];

  validationRegex = [
    {
      id: '2',
      value: '^[a-zA-Z ]*$',
      displayName: 'Alphabet',
    },
    {
      id: '3',
      value: '^[a-zA-Z0-9 !@#$%^&*()\\-_=+[\\]{};:\'",.<>/?]*$',
      displayName: 'Alphanumeric',
    },
    {
      id: '4',
      value: '^[+-]?(\\d+\\.?\\d*|\\.\\d+)$',
      displayName: 'Decimal',
    },
    {
      id: '5',
      value: '^[^s@]+@[^s@]+.[^s@]+$',
      displayName: 'Email',
    },
    {
      id: '1',
      value: '^[0-9]*$',
      displayName: 'Numeric',
    },
  ];
  // '^[a-zA-Z0-9 ]*$'
  projectId_forAdmin = 'd0672c2e-dbd0-4bfb-b35d-078b2ca72d52'; // Wash project
  programId: string = '94d7401e-ed40-475b-8696-6836b2b711e0'; // wash Program

  public showSwal(
    type: 'warning' | 'error' | 'success' | 'info' | 'question',
    title: any,
    message: string = null
  ) {
    Swal.fire({
      position: 'center',
      icon: type,
      title: title,
      text: message,
      showConfirmButton: false,
      timer: 5000,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
        toast.addEventListener('click', () => Swal.close());
      },
    });
  }

  public showSwalWithToast(
    title: string = 'Success',
    message: string = 'Request successful.',
    type: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success',
    timerProgressBar: boolean = true,
    timer: number = 3000,
    showConfirmButton: boolean = false,
    showCloseButton: boolean = true
  ) {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showCloseButton: showCloseButton,
      showConfirmButton: showConfirmButton,
      icon: type === 'success' ? undefined : type,
      title: title,
      html: message,
      iconHtml:
        type === 'success'
          ? '<img src="../../../../assets/images/icon/success-mark.svg" class="success-icon">'
          : undefined,
      timerProgressBar: timerProgressBar,
      timer: timer,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
        toast.addEventListener('click', () => Swal.close());
      },
      customClass: {
        title: 'custom-title',
        popup: 'custom-popup',
        htmlContainer: 'custom-message',
        timerProgressBar: 'custom-timer',
      },
    });
  }

  public async showSwalAndReturn(params: {
    title?: string;
    message?: string;
    type?: any;
    color?: string;
    width?: string;
    imageUrl?: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    confirmButtonColor?: string;
    cancelButtonColor?: string;
    showCancelButton: boolean;
    showCloseButton?: boolean;
    closeOnOutsideClick?: boolean;
  }) {
    return await Swal.fire({
      title: params.title || 'Are you sure?',
      text: params.message,
      color: params.color,
      icon: params.type,
      width: params.width || '800',
      imageUrl: params.imageUrl,
      showCancelButton: params.showCancelButton,
      confirmButtonText: params.confirmButtonText || 'Yes, delete it!',
      cancelButtonText: params.cancelButtonText || 'No',
      confirmButtonColor: params.confirmButtonColor || '#0054a6',
      cancelButtonColor: params.cancelButtonColor || '#ffd602',
      showCloseButton: params.showCloseButton || false,
      allowOutsideClick: params.closeOnOutsideClick || false,
      customClass: {
        title: 'swal2-title-custom',
        confirmButton: 'swal2-confirm-custom',
        cancelButton: 'swal2-cancel-custom',
      },
    });
  }

  public showSpinner(isSpinning: boolean) {
    if (isSpinning) {
      this.spinner.show();
    } else {
      this.spinner.hide();
    }
  }

  // public errorResponseHandler(error: IErrorResponse) {
  //   if (error.code === 400 || error.code === 404 || error.code === 409) {
  //     this.showSwalWithToast(error.error_name, error.message, 'warning');
  //   } else {
  //     this.showSwalWithToast('Warning', 'Something went wrong', 'error');
  //   }
  // }

  public responseHandler(result: {
    status: string;
    result: any;
    resultset: any[];
  }) {
    if (result && result.status === 'ok') {
      return result?.resultset ?? result.result;
    }
    return null;
  }

  public responseHandlerWithAlert(
    obs$: Observable<any>,
    isShowAlert: boolean = false
  ) {
    return obs$.pipe(
      map((result: any) => {
        if (result && result.status === 'ok') {
          if (isShowAlert) {
            this.showSwal('success', 'Successful!');
          }
          return result?.resultset ?? result?.result;
        }
      }),
      catchError((err: any) => {
        if (isShowAlert) {
          if (
            err.error.code === 400 ||
            err.error.code === 404 ||
            err.error.code === 409
          ) {
            this.showSwal('warning', err.error.error_name, err.error.message);
          } else {
            this.showSwal('warning', 'Something went wrong');
          }
          return null;
        }
        return throwError(() => err);
      })
    );
  }

  public getSlNo(result: any[]) {
    let slNo = 1;
    result.forEach((element: any) => {
      element.slNo = slNo++;
    });
    return result;
  }

  public addCustomProperty(
    result: any[],
    from: string,
    forHOUser: boolean = false
  ): any[] {
    switch (from) {
      case 'ProjectList':
        return result.map((item) => ({
          ...item,
          start_custom: this.formatDate(item.start),
          end_custom: this.formatDate(item.end),
          is_active_custom: item.is_active ? 'Active' : 'Inactive',
        }));
      case 'GeneralList':
        return result.map((item) => ({
          ...item,
          is_active_custom: item.is_active ? 'Active' : 'Inactive',
        }));
      case 'ProjectActivityMappingList':
        return result.map((item) => ({
          ...item,
          checklist: {
            ...item.checklist,
            is_active_custom: item.checklist.is_active ? 'Active' : 'Inactive',
          },
        }));
      case 'SubordinateStaffs':
        return result.map((item) => ({
          ...item,
          custom_name:
            item.name +
            (item.role_short_form ? ', ' + item.role_short_form : '') +
            '- ' +
            item.pin,
          custom_name_obj: {
            name:
              item.name +
              (item.role_short_form ? ', ' + item.role_short_form : ''),
            id: forHOUser ? item.id : item.user_id,
          },
        }));
      default:
        return result.map((item) => ({
          ...item,
        }));
    }
  }

  public getFiltered(
    array: any[],
    key: string,
    compValue: any,
    isNotEqual: boolean = false
  ) {
    return array.filter((data) => {
      if (data && data?.hasOwnProperty(key)) {
        return isNotEqual ? data[key] !== compValue : data[key] === compValue;
      }
      return false;
    });
  }
  public dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a: any, b: any) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      var result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  public dynamicSortWithDate(property, sortOrderType = 'asc') {
    var sortOrder = sortOrderType.toLowerCase() === 'desc' ? -1 : 1;

    return function (a, b) {
      var dateA = new Date(a[property]);
      var dateB = new Date(b[property]);

      if (dateA < dateB) return -1 * sortOrder;
      if (dateA > dateB) return 1 * sortOrder;
      return 0;
    };
  }

  public formattedDateddMMyyyy(
    dateValue: string,
    format: 'dd-mm-yyyy' | 'dd-mmm-yyyy' | 'dd-mmmm-yyyy',
    separator: '-' | '/' | ' '
  ): string {
    const date = new Date(dateValue);

    // Get year, month, and day part from the date
    const year = date.toLocaleString('default', { year: 'numeric' });
    const monthNo = date.toLocaleString('default', { month: '2-digit' });
    const fullMonthName = date.toLocaleString('default', { month: 'long' });
    const shortMonthName = date.toLocaleString('default', { month: 'short' });
    const day = date.toLocaleString('default', { day: '2-digit' });

    // Generate yyyy-mm-dd date string
    let formattedDate;
    switch (format) {
      case 'dd-mm-yyyy':
        formattedDate = day + separator + monthNo + separator + year;
        break;
      case 'dd-mmm-yyyy':
        formattedDate = day + separator + shortMonthName + separator + year;
        break;
      case 'dd-mmmm-yyyy':
        formattedDate = day + separator + fullMonthName + separator + year;
        break;
    }
    return formattedDate;
  }

  public formatDate(inputDate: Date) {
    const date = new Date(inputDate);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  }

  public getUserLocation() {
    const that = this;
    if ('geolocation' in navigator) {
      // Geolocation is available
      this.showSpinner(true);
      navigator.geolocation.getCurrentPosition(
        function (position) {
          console.log('Latitude: ' + position.coords.latitude);
          console.log('Longitude: ' + position.coords.longitude);
          console.log(position.coords);

          that.showSpinner(false);

          // You can now use the latitude and longitude in your application
          return {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
        },
        function (error) {
          // Handle any errors that occur during geolocation
          that.showSpinner(false);
          that.showSwalWithToast(
            'Location Not Found',
            'Please check your browser location permission enable or not for this website.',
            'warning'
          );

          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.error('User denied the request for Geolocation.');
              break;
            case error.POSITION_UNAVAILABLE:
              console.error('Location information is unavailable.');
              break;
            case error.TIMEOUT:
              console.error('The request to get user location timed out.');
              break;
            // case error.UNKNOWN_ERROR:
            //   console.error("An unknown error occurred.");
            //   break;
          }
        }
      );
    } else {
      // Geolocation is not available in this browser
      this.showSpinner(false);
      console.error('Geolocation is not available in your browser.');
      that.showSwalWithToast(
        'Location Not Found',
        'Geolocation is not available in your browser.',
        'warning'
      );
    }
  }

  public calculateAge(dob: Date | string) {
    const timeDiff = Math.abs(Date.now() - new Date(dob).getTime());
    return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
  }

  isAddPermission(): boolean {
    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    if (selectedMenu != null) {
      return selectedMenu?.actions?.create;
    } else {
      return false;
    }
  }

  isEditPermission(): boolean {
    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    if (selectedMenu != null) {
      return selectedMenu?.actions?.update;
    } else {
      return false;
    }
  }

  isRemovePermission(): boolean {
    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    if (selectedMenu != null) {
      return selectedMenu?.actions?.remove;
    } else {
      return false;
    }
  }

  isGetPermission(): boolean {
    let selectedMenu = this.localStorageService.getSelectedMenuInfo();
    if (selectedMenu != null) {
      return selectedMenu?.actions?.get;
    } else {
      return false;
    }
  }

  public getAllCountries(): Observable<any[]> {
    return this.http.get('', {
      baseUrl: 'https://restcountries.com/v3.1/all',
      skipAuth: true,
    });
  }

  public static isValidEmail(email: string): boolean {
    // Regular expression for validating an email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  public sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public jsonParse(str: any): any {
    try {
      return JSON.parse(str);
    } catch (error) {
      console.log(error);
      return str;
    }
  }

  public static getUrl(file: string): any {
    try {
      return JSON.parse(file).url;
    } catch (error) {
      console.log(error);
      return file;
    }
  }

  // onExportingFromDataGrid(e, fileName) {
  //   if (e.format === 'pdf') {
  //     const doc = new jsPDF();
  //     exportDataGridToPdf({
  //       jsPDFDocument: doc,
  //       component: e.component,
  //     }).then(() => {
  //       doc.save(fileName + '.pdf');
  //     });
  //   } else {
  //     const workbook = new Workbook();
  //     const worksheet = workbook.addWorksheet(fileName);

  //     exportDataGridToXLSX({
  //       component: e.component,
  //       worksheet,
  //       autoFilterEnabled: true,
  //     }).then(() => {
  //       workbook.xlsx.writeBuffer().then((buffer) => {
  //         saveAs(
  //           new Blob([buffer], { type: 'application/octet-stream' }),
  //           fileName + '.xlsx',
  //         );
  //       });
  //     });
  //     e.cancel = true;
  //   }
  // }

  // private toastExcludeHandler(responseType: string, url: string): string {
  //   return responseType === HttpRequestSuccessFailed.SUCCESS &&
  //     (url.includes('account/login') ||
  //       url.includes('notifications/unregister') ||
  //       url.includes('notifications/register'))
  //     ? ''
  //     : responseType;
  // }
}
