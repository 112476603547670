export class CampaignDonationModel {
  ngo_id: string;
  project_id: string;
  id: string;
  cover_img_vdo: string;
  created_at: string;
  created_by: string;
  description: string;
  donation_summary: string;
  end_date: string;
  fundraising_target: number;
  is_active: boolean;
  is_fundraiser_private: boolean;
  ngo_name: string;
  project_name: string;
  start_date: string;
  status: string;
  subtitle: string;
  total_donors: number;
  total_fund_raised: number;
  title: string;
  updated_at: string;
  updated_by: string;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}

export class CampaignDonationTransactionModel {
  name: string = null;
  email: string = null;
  comment: string = null;
  amount: number = 0;
  transaction_fees: number = 0;
  is_video_call_active: boolean = false;
  whatsapp_no: string = null;
  is_hidden_name: boolean = false;
  is_hidden_comment: boolean = false;
  can_contact: boolean = true;
  is_hidden_name_and_amount: boolean = false;
  is_featured_donation: boolean = false;
  ngo_name: string = null;
  campaign_id: string = null;
  campaign_title: string = null;
  fundraiser_id: string = null;
  project_id: string = null;
  ngo_id: string = null;
  team_id: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
