import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import {
  HttpContextToken,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { AuthService } from '../../services/auth/auth.service';
import { catchError, map, throwError } from 'rxjs';
import { ISuccessSwal } from '../../interface/http-request -options.interface';

export const BYPASS_TOKEN = new HttpContextToken(() => false);
export const CUSTOM_BASE_URL = new HttpContextToken<string | null>(() => null);
export const SHOW_SPINNER = new HttpContextToken<boolean | null>(() => true);
export const SHOW_SUCCESS_SWAL = new HttpContextToken<ISuccessSwal | null>(
  () => null
);
export const HIDE_SUCCESS_SWAL = new HttpContextToken<boolean | null>(
  () => false
);

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private jwtHelper = new JwtHelperService();

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    const customBaseUrl = req.context.get(CUSTOM_BASE_URL);
    const showSpinner = req.context.get(SHOW_SPINNER);
    const showSuccessSwal = req.context.get(SHOW_SUCCESS_SWAL);
    const hideSuccessSwal = req.context.get(HIDE_SUCCESS_SWAL);

    const baseUrl = customBaseUrl || environment.apiURL;

    let modifiedReq = req.clone({
      url: `${baseUrl}/${req.url}`,
    });

    // If the BYPASS_TOKEN is set to true, skip adding the Authorization header
    if (req.context.get(BYPASS_TOKEN)) {
      return this.responseHandler(
        modifiedReq,
        next,
        req.method,
        showSpinner,
        showSuccessSwal,
        hideSuccessSwal
      );
    }

    // If the authorization is missing or user not logged in
    const userToken = this.localStorageService.getToken();
    if (!userToken) {
      this.router.navigateByUrl('/auth/login');
      return;
    }

    // If authorization token is expired
    const isExpired = this.jwtHelper.isTokenExpired(userToken);
    if (isExpired) {
      this.authService.logout();
      this.router.navigateByUrl('/auth/login');
      return;
    }

    // Append the Authorization token to the headers if not bypassed
    modifiedReq = modifiedReq.clone({
      headers: req.headers.set('Authorization', `Bearer ${userToken}`),
    });

    return this.responseHandler(
      modifiedReq,
      next,
      req.method,
      showSpinner,
      showSuccessSwal,
      hideSuccessSwal
    );
  }

  private responseHandler = (
    request: HttpRequest<unknown>,
    next: HttpHandler,
    requestMethod: string,
    showSpinner: boolean,
    showSuccessSwal: ISuccessSwal,
    hideSuccessSwal: boolean
  ) => {
    this.utilitiesService.showSpinner(showSpinner);
    return next.handle(request).pipe(
      map((response: HttpEvent<any>) => {
        // Check if the response is of type HttpResponse
        if (response instanceof HttpResponse) {
          //Show swal if showSuccessSwal is present
          let title = 'SUCCESSFUL';
          let message = null;
          if (showSuccessSwal) {
            title = showSuccessSwal.title ? showSuccessSwal.title : title;
            message = showSuccessSwal.message
              ? showSuccessSwal.message
              : message;
          }

          if (!(requestMethod === 'GET') && !hideSuccessSwal) {
            this.utilitiesService.showSwalWithToast(title, message, 'success');
          }

          // Clone the response and modify the body and return the response
          let modifiedBody = response.body;
          return response.clone({ body: modifiedBody });
        }
        this.utilitiesService.showSpinner(false);
        return response;
      }),
      catchError((error) => {
        this.utilitiesService.showSpinner(false);
        // Log the error or display an error message
        this.utilitiesService.showSwalWithToast(
          error.error.error_name,
          error.error.message,
          'error'
        );
        return throwError(() => error);
      })
    );
  };
}
