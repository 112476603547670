<div class="body">
  <div *ngIf="announcement" class="announcement-badge">Announcement</div>
  <p *ngIf="announcement">
    {{ announcement?.announcement_date | date : "dd/mm/yyyy" }}
  </p>

  <h1>{{ announcement?.title }}</h1>

  <div id="announcement_body"></div>

  <hr *ngIf="announcement" class="line" />
</div>
