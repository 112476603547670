import * as uuid from 'uuid';

export class FundraiserTeamModel {
  ngo_id: string = null;
  project_id: string = null;
  campaign_id: string = null;
  fundraiser_name: string = null;
  team_owner_id: string = uuid.v4();
  team_name: string = null;
  team_logo: string = null;
  fundraising_target: number = null;
  fund_raised: number = 0;
  story: string = null;

  constructor() {}
}

export class FundraiserModel {
  ngo_id: string = null;
  project_id: string = null;
  campaign_id: string = null;
  fundraiser_name: string = null;
  team_id: string = null;
  team_name: string = null;
  profile_img: string = null;
  fundraising_target: number = null;
  fund_raised: number = null;
  story: string = null;
  is_team_owner: boolean = false;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}
