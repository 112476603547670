import { JoinAsFundraiserComponent } from './../join-as-fundraiser/join-as-fundraiser.component';
import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { RichTextService } from 'src/app/shared/services/rich-text/rich-text.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { ApprovalStatusEnum } from 'src/app/shared/enum/approval-category.enum';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrl: './campaign-details.component.scss',
})
export class CampaignDetailsComponent {
  @Input() showDonationSummary: boolean = true;
  @Input() showMoreDonorExperienceButton: boolean = true;
  @Input() showFundraisers: boolean = true;
  @Input() superAdminDashboard: boolean = false;
  
  public donationList: any;
  public currentDonationList: any;
  private donationListFetched: boolean = false; // Add this flag to donation data available or not
  public dayLeft: number | string;
  public donationListPopUp = false;
  public topDonations: any[];
  public recentDonations: any[];

  public ngo_id: string;
  public project_id: string;
  public campaign_id: string;

  public campaign: any;
  public approvalStatusEnum = ApprovalStatusEnum;

  public isCommentHasData: boolean = false;
  public comment_reply: any[] = [];

  @ViewChild(JoinAsFundraiserComponent)
  joinAsFundraiserComponent: JoinAsFundraiserComponent;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly donationService: DonationService,
    private readonly richTextService: RichTextService,
    public readonly utilitiesService: UtilitiesService,
    public readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.campaign_id = decryptedParams.id;
        this.ngo_id = decryptedParams.ngo_id;
        this.project_id = decryptedParams.project_id;
        this.getCampaignDonationById(
          this.ngo_id,
          this.project_id,
          this.campaign_id
        );
      } else {
        this.router.navigateByUrl('/dashboard/campaign');
        console.error('Decryption failed');
      }
    });
  }

  private getCampaignDonationById(
    ngo_id: string,
    project_id: string,
    campaign_id: string
  ) {
    this.utilitiesService.showSpinner(true);
    this.donationService
      .getDonationCampaignById(ngo_id, project_id, campaign_id)
      .subscribe({
        next: (result) => {
          this.onSuccess(result);
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
        },
      });
  }

  private onSuccess(result) {
    this.utilitiesService.showSpinner(false);
    this.campaign = result;

    this.campaign.cover_img_vdo = JSON.parse(this.campaign?.cover_img_vdo);
    this.campaign.story_rich_text = this.richTextService.decodeHtml(
      this.campaign.story_rich_text
    );
    this.campaign.donation_summary = JSON.parse(this.campaign.donation_summary);

    const element = document.getElementById('campaign_story');
    element.innerHTML = this.campaign.story_rich_text;

    this.getDaysLeft(this.campaign.end_date);
  }

  private getDaysLeft(endDate: string) {
    if (this.campaign.end_date) {
      const now = new Date().getDate();
      const end = new Date(endDate).getDate();

      if (end >= now) {
        this.dayLeft = Math.ceil((end - now) / (1000 * 3600 * 24));
      } else {
        this.dayLeft = 0;
      }
    } else {
      this.dayLeft = 'Ongoing';
    }
  }

  public getDonationTime(createdAt: string): string {
    const donationDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDiffInMs = currentDate.getTime() - donationDate.getTime();
    const hours = Math.floor(timeDiffInMs / (1000 * 60 * 60));
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else {
      return `${hours + 1} hour${hours > 1 ? 's' : ''}`;
    }
  }

  public filterDonations(type: 'recent' | 'top') {
    if (type === 'recent') {
      this.currentDonationList = this.donationList.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    } else if (type === 'top') {
      this.currentDonationList = this.donationList.sort(
        (a, b) => b.amount - a.amount
      );
    }
  }

  public closePopup() {
    this.donationListPopUp = false;
  }

  public seeAll() {
    this.donationListPopUp = true;
    if (!this.donationListFetched) {
      this.utilitiesService.showSpinner(true);
      this.donationService
        .getDonationListByCampaign(
          this.ngo_id,
          this.project_id,
          this.campaign_id
        )
        .subscribe({
          next: (result) => {
            this.donationListSuccess(result);
          },
          error: (err) => {
            this.utilitiesService.showSpinner(false);
          },
        });
    } else {
      this.filterDonations('recent');
    }
  }

  private donationListSuccess(result: any) {
    this.utilitiesService.showSpinner(false);
    this.donationList = result?.donations;
    this.donationListFetched = true;
    this.filterDonations('recent');
  }

  get getFundRaisedInPercent(): number {
    if (
      this.campaign?.total_fund_raised === null ||
      this.campaign?.total_fund_raised === 0
    ) {
      return 0;
    } else {
      return Math.round(
        (this.campaign?.total_fund_raised / this.campaign?.fundraising_target) *
          100
      );
    }
  }

  public navigateToCampaignDonation(campaign: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
    });
    this.router.navigate(['/donate-to-campaign'], {
      queryParams: { data: encryptedParams },
    });
  }
}
