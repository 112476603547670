import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NewsArticleTypeEnum } from '../../enum/news-article.enum';
import { Router } from '@angular/router';
import { ShohayBlogService } from '../../services/shohay-blog/shohay-blog.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';

@Component({
  selector: 'app-news-article-cards',
  templateUrl: './news-article-cards.component.html',
  styleUrl: './news-article-cards.component.scss',
})
export class NewsArticleCardsComponent implements OnInit {
  @ViewChild('articleButton') articleButton!: ElementRef;
  @ViewChild('newsButton') newsButton!: ElementRef;

  private articleNewsOriginalList: any[] = [];
  public articleNewsList: any[] = [];
  public newsArticleTypeEnum = NewsArticleTypeEnum;

  constructor(
    private router: Router,
    private readonly shohayBlogService: ShohayBlogService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  ngOnInit(): void {
    this.getArticleNewsList();
  }

  private getArticleNewsList(): void {
    this.shohayBlogService.getArticleNews(1, 12).subscribe((result) => {
      this.articleNewsOriginalList = result.data.splice(0, 4);
      this.articleNewsList = JSON.parse(
        JSON.stringify(this.articleNewsOriginalList)
      );
    });
  }

  public toggleArticleNews(
    filterValue: string,
    class1: string,
    class2: string
  ): void {
    this.newsButton.nativeElement.classList.remove(class1);
    this.newsButton.nativeElement.classList.add(class2);
    this.articleButton.nativeElement.classList.remove(class2);
    this.articleButton.nativeElement.classList.add(class1);

    this.articleNewsList = this.articleNewsOriginalList.filter(
      (article) => article.blog_type === filterValue
    );
  }

  // public goToArticleNews(id: number, newsArticleId: string): void {
  //   window.open(
  //     `/news-article?newsArticleId=${newsArticleId}&id=${id}`,
  //     '_blank'
  //   );
  // }

  public goToArticleNews(id: number, newsArticleId: string): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      newsArticleId: newsArticleId,
      id: id,
    });
    this.router.navigate(['/news-article'], {
      queryParams: { data: encryptedParams },
    });
  }
}
