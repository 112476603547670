import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root',
})
export class RichTextService {
  constructor(private readonly fileService: FileService) {}

  public async processHtmlString(richTextString: string): Promise<string> {
    const parser = new DOMParser();
    const doc = parser.parseFromString(richTextString, 'text/html');
    const images = Array.from(doc.querySelectorAll('img'));

    for (let img of images) {
      try {
        const src = img.getAttribute('src');

        if (src && this.isBase64Image(src)) {
          const file = this.base64ToFile(src);
          const imageUrl = await lastValueFrom(
            this.fileService.uploadSingleFile(file)
          );

          img.setAttribute('src', imageUrl[0].url);
        }
      } catch (error) {
        throw error;
      }
    }
    return this.encodeHtml(doc.body.innerHTML);
  }

  private isBase64Image(src: string): boolean {
    return src.startsWith('data:image/');
  }

  private base64ToFile(base64: string): File {
    const arr = base64.split(','); // Split the base64 string to get the mime type and the actual base64 data
    const mime = arr[0].match(/:(.*?);/)[1]; // Extract the MIME type
    const binaryString = atob(arr[1]); // Decode base64 to binary string
    const len = binaryString.length;
    const uint8Array = new Uint8Array(len);

    // Convert binary string to bytes
    for (let i = 0; i < len; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }
    return new File([uint8Array], 'image', { type: mime });
  }

  private encodeHtml(html: string): string {
    const div = document.createElement('div');
    div.innerText = html;
    return div.innerHTML;
  }

  public decodeHtml(html: string): string {
    const div = document.createElement('div');
    div.innerHTML = html;
    return div.innerText || '';
  }
}
