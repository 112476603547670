import { Injectable } from '@angular/core';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { UrlEnum } from '../../enum/url.enum';
import { map, Observable } from 'rxjs';
import {
  FundraiserModel,
  FundraiserTeamModel,
} from '../../model/fundraiser.model';

@Injectable({
  providedIn: 'root',
})
export class FundraiserService {
  private fundraiserAPI = `${UrlEnum.SHOHAY_NGO}/ngo-donation/fund-raiser`;
  private fundraiserTeamAPI = `${UrlEnum.SHOHAY_NGO}/ngo-donation/fund-raiser-team`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public getAllTeams(campaignId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.fundraiserTeamAPI}/get-by-campaign/${campaignId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getAllTeamsWithFundraisers(campaignId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.fundraiserAPI}/get-all-by-campaign/${campaignId}`, {
        skipAuth: true,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createTeam(teamBody: FundraiserTeamModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.fundraiserTeamAPI}/create`, teamBody)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public joinAsFundraiser(body: FundraiserModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.fundraiserAPI}/create`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getFundraiserHistoryByUserID(UserId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.fundraiserAPI}/get-by-user/${UserId}`, {
        skipAuth: true,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
