import { Component } from '@angular/core';

@Component({
  selector: 'app-campaign-gallery',
  templateUrl: './campaign-gallery.component.html',
  styleUrl: './campaign-gallery.component.scss',
})
export class CampaignGalleryComponent {
  public actionTitle: string = '        ';

  public gallery: any[] = Array(10).fill(10);
  public imageList: boolean[] = Array(this.gallery.length).fill(false);
  private selectedImage: number;

  constructor() {}

  public changeActionTitle(e: any): void {
    console.log(e);
    this.actionTitle = e.itemData.text;
  }

  public selectImage(e: any, index: number): void {
    console.log(e);
    // if (e.value) {
      console.log(this.imageList);
      if (this.selectedImage != null && this.selectedImage !== index) {
        this.imageList[this.selectedImage] = false;
        console.log(this.imageList);
        console.log(this.imageList);
      }
      if (e.currentTarget.checked) {
        this.selectedImage = index;
        this.imageList[index] = true;
        console.log(this.imageList);
        console.log(this.imageList);
      } else if(this.selectedImage) {
        this.imageList[this.selectedImage] = false;
        this.selectedImage = null;
        console.log(this.imageList);
        console.log(this.imageList);
      }
    // }
  }

  public action(): void {
    this[this.actionTitle.toLowerCase() + 'Images']();
  }

  private deleteImages(): void {
    console.log('deleteImages');
  }
  private shareImages(): void {
    console.log('shareImages');
  }
  private downloadImages(): void {
    console.log('downloadImages');
  }
}
