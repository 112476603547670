import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { UrlEnum } from '../../enum/url.enum';
import { ChangePasswordModel } from '../../model/auth/change-password.model';
import { LoginBodyModel } from '../../model/auth/login.model';
import { OtpResendModel } from '../../model/auth/otp-resend.model';
import { OtpValidationModel } from '../../model/auth/otp-validation.model';
import { SignupBodyModel } from '../../model/auth/signup.model';
import { HttpRequestService } from '../http-request/http-request.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { UtilitiesService } from '../utilities/utilities.service';
import { ForgetPasswordModel } from '../../model/auth/forget-password.model';
import { ResetPasswordModel } from '../../model/auth/reset-password.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public shouldShowToast = false;
  private loginApi = UrlEnum.SHOHAY_AUTH;

  constructor(
    private readonly httpRequestService: HttpRequestService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public isLoggedIn(): boolean {
    return this.localStorageService.getToken() ? true : false;
  }

  public login(body: LoginBodyModel): Observable<any> {
    return this.httpRequestService.post(
      `${this.loginApi}/account/login`,
      body,
      { skipAuth: true }
    );
  }

  public logout() {
    this.localStorageService.deleteToken();
    this.localStorageService.clearSessionUser();
  }

  public signup(body: SignupBodyModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.loginApi}/users`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public changePassword(body: ChangePasswordModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.loginApi}/account/reset-password`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public resendOtp(body: OtpResendModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.loginApi}/otp/resend`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public validateOtp(body: OtpValidationModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.loginApi}/otp/validate`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public sendMail(body: ForgetPasswordModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.loginApi}/reset-password/request`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public resetPassword(body: ResetPasswordModel): Observable<any> {
    return this.httpRequestService
      .patch(`${this.loginApi}/reset-password`, body, { skipAuth: true })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
