<div class="top_div">
  <div class="main_div">
    @if(!popup){
      <div class="title_full_div">
        <div class="title_div">
          <div class="title_privacy">Privacy Policy</div>
          <div class="sub_title">Your Privacy, Our Promise to Protect.</div>
        </div>
      </div>
    }
    <div class="white_div_container">
      <div class="white_div">
        <div class="policy_1">
          <div class="title">
            <span class="last-update">Last Updated:</span> October 24, 2024
          </div>
          <div class="description">
            Shohay is dedicated to protecting your personal information. We
            maintain transparency in the collection, usage, and protection of
            your data. This Privacy Policy outlines how Shohay manages your
            information when you utilize our platform, including the website,
            mobile application, and related services (collectively, the
            “Platform”).
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Definitions</div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights">Shohay:</span> Refers to the
                organization that operates the Platform.
              </li>
              <li>
                <span class="highlights">Platform: </span> Refers to the Shohay
                website, mobile application, and all related services.
              </li>
              <li>
                <span class="highlights">User: </span> Any individual or
                organization that accesses or uses the Platform
              </li>
              <li>
                <span class="highlights">NGO: </span> Non-governmental
                organizations that create or manage profiles on the Platform.
              </li>
              <li>
                <span class="highlights">Donor: </span> Individuals or
                organizations that make donations through the Platform
              </li>
            </ul>
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Information We Collect</div>
          <div class="definition_sub">
            To provide you with the best possible experience on Shohay.
          </div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights">Personal Data:</span> Includes name,
                email address, phone number, payment information, and other data
                shared during registration, donation, or NGO management.
              </li>
              <li>
                <span class="highlights">Usage Data: </span> Information like
                your IP address, device type, browser type, and your interaction
                with the Platform (e.g., pages visited, features used).
              </li>
              <li>
                <span class="highlights">Cookies and Tracking: </span> We use
                cookies and similar technologies to collect information on how
                you use Shohay. You can manage your cookie preferences through
                your browser settings.
              </li>
            </ul>
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">How We Use Your Information</div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights">Providing Services:</span> To manage
                your account, process donations, and enable interactions between
                users, NGOs, and Shohay.
              </li>
              <li>
                <span class="highlights">Platform Improvement: </span>To analyze
                how you use the Platform and enhance features, usability, and
                security.
              </li>
              <li>
                <span class="highlights">Communications: </span> To send you
                transactional emails (e.g., donation confirmations), updates,
                and newsletters.
              </li>
              <li>
                <span class="highlights">Legal Compliance: </span> To comply
                with legal obligations, prevent fraud, and meet reporting
                requirements.
              </li>
              <li>
                <span class="highlights">Marketing: </span> To provide you with
                information on donation opportunities that you may be interested
                in.
              </li>
            </ul>
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Sharing Your Information</div>
          <div class="definition_sub">
            Shohay never sells your data. We share your personal information in
            limited, necessary circumstances:
          </div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights">Service Providers: </span> We partner
                with third-party service providers (e.g., payment processors,
                email marketing services) to deliver our services. These
                partners are required to protect your data and only use it for
                their specific tasks under Data Processing Agreements.
              </li>
              <li>
                <span class="highlights">NGOs: </span> When you donate to an
                NGO, relevant details (excluding payment info) are shared with
                the NGO to ensure transparency.
              </li>
              <li>
                <span class="highlights">Legal Obligations:</span> If required
                by law or to protect our legal rights, we may share your
                information with relevant authorities.
              </li>
            </ul>
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Data Security</div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights"> Encryption: </span>Personal and
                financial data is encrypted during transmission and storage
                using industry-standard security protocols (e.g., SSL/TLS).
              </li>
              <li>
                <span class="highlights"> Access Controls: </span> We limit
                access to personal data to authorized personnel only, ensuring
                they follow strict confidentiality rules.
              </li>
              <li>
                <span class="highlights">Regular Security Audits: </span> Our
                systems are routinely tested and monitored to identify
                vulnerabilities and ensure compliance with security standards.
              </li>
              <li>
                <span class="highlights">Data Breach Notification: </span> In
                the event of a data breach, we will notify the relevant
                authorities within 72 hours if your data privacy is at risk. You
                will also be informed if the breach poses a high risk to your
                rights and freedoms.
              </li>
            </ul>
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Data Retention</div>
          <div class="terms">
            We retain your personal information only for as long as necessary to
            fulfill the purposes outlined in this Privacy Policy or as required
            by law. Once your data is no longer needed, we securely delete or
            anonymize it.
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Your Privacy Rights</div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights"> Access: </span> You can request a copy
                of the personal data we hold about you.
              </li>
              <li>
                <span class="highlights"> Correction: </span> You can ask us to
                correct or update inaccurate information.
              </li>
              <li>
                <span class="highlights">Deletion: </span> You may request that
                we delete your personal data, subject to legal obligations.
              </li>
              <li>
                <span class="highlights">Data Portability: </span> You have the
                right to receive your personal data in a structured, commonly
                used, and machine-readable format, and to transfer it to another
                controller.
              </li>
              <li>
                <span class="highlights"> Restriction: </span> You can request
                that we restrict processing of your data under certain
                conditions.
              </li>
              <li>
                <span class="highlights"> Objection: </span> You may object to
                the processing of your data for direct marketing or where we are
                relying on legitimate interests as a basis for processing.
              </li>
              <li>
                <span class="highlights"> Opt-Out: </span> You can unsubscribe
                from our communications or adjust cookie preferences.
              </li>
            </ul>
            To exercise any of these rights, please contact us at
            <span class="shohay_email">privacy&#64;shohay.net.</span>
          </div>
        </div>
        <div class="policy_2">
          <div class="definiton">Cookies and Tracking Technologies</div>
          <div class="terms">
            <ul>
              <li>
                <span class="highlights"> Customization: </span> Tailoring
                content and features based on your preferences.
              </li>
              <li>
                <span class="highlights"> Analytics: </span> Analyzing how you
                use the Platform to improve functionality and performance.
              </li>
              <li>
                <span class="highlights">Security: </span> Ensuring safe and
                secure access to your account.
              </li>
            </ul>
            You can control or disable cookies via your browser settings.
            However, please note that disabling cookies may limit some features
            of the Platform.
          </div>
        </div>
        <div class="policy_1">
          <div class="definiton">International Data Transfers</div>
          <div class="description">
            Shohay operates globally, and your personal data may be transferred
            to and processed in countries other than your own. We implement
            safeguards for international transfers, such as Standard Contractual
            Clauses (SCCs), to ensure that such transfers comply with applicable
            data protection laws and provide an adequate level of protection for
            your data.
          </div>
        </div>
        <div class="policy_1">
          <div class="definiton">Children’s Privacy</div>
          <div class="description">
            Shohay is not intended for individuals under the age of 16. We do
            not knowingly collect personal information from children. If you
            believe that we have inadvertently collected such data, please
            contact us, and we will promptly delete it.
          </div>
        </div>
        <div class="policy_1">
          <div class="definiton">Automated Decision-Making and Profiling</div>
          <div class="description">
            Shohay does not engage in automated decision-making or profiling
            that would produce legal or significant effects on users. Should
            this change, we will update this policy to inform you of your rights
            in relation to such processing.
          </div>
        </div>
        <div class="policy_1">
          <div class="definiton">Data Protection Officer (DPO)</div>
          <div class="description">
            Shohay has appointed a Data Protection Officer (DPO) responsible for
            overseeing GDPR compliance. For questions or concerns about data
            protection, you may reach out to our DPO at support&#64;shohay.net.
          </div>
        </div>
        <div class="policy_1">
          <div class="definiton">Changes to This Privacy Policy</div>
          <div class="description">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or legal requirements. When updates are
            made, we will notify you through the Platform, and your continued
            use of Shohay will indicate your acceptance of the updated terms.
          </div>
        </div>
        <div class="policy_1">
          <div class="definiton">Contact Us</div>
          <div class="description_">
            <div class="description">
              If you have any questions or concerns regarding this Privacy
              Policy, please feel free to reach out to us:
            </div>
            <div>
              <span class="highlights block">Shohay Support Team</span>
              <div>
                <span class="highlights">Email: </span>
                <span class="shohay_email">support&#64;shohay.net</span>
              </div>
              <span class="highlights">Address:</span> 53 Purana Paltan, Dhaka,
              Bangladesh
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
