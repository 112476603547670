import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CampaignDonationModel } from 'src/app/shared/model/campaign-donation.model';
import { DonationService } from 'src/app/shared/services/donation/donation.service';
import { FundraiserService } from 'src/app/shared/services/fundraiser/fundraiser.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-donation-summary-shared',
  templateUrl: './donation-summary-shared.component.html',
  styleUrl: './donation-summary-shared.component.scss',
})
export class DonationSummarySharedComponent {
  @Input() campaign: CampaignDonationModel = new CampaignDonationModel();
  @Input() donationSummary: any;
  @Input() ngo_id: string;
  @Input() project_id: string;
  @Input() campaign_id: string;
  @Input() isCampaignHasData: boolean;

  public donationList: any;

  private donationListFetched: boolean = false;
  public donationListPopUp = false;

  public currentDonationList: any;
  public activeFilter: string = 'recent';

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly donationService: DonationService,
    private readonly utilitiesService: UtilitiesService,
    private readonly fundraiserService: FundraiserService,
    private readonly spinner: NgxSpinnerService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public seeAll() {
    this.donationListPopUp = true;
    if (!this.donationListFetched) {
      this.utilitiesService.showSpinner(true);
      this.donationService
        .getDonationListByCampaign(
          this.ngo_id,
          this.project_id,
          this.campaign_id
        )
        .subscribe({
          next: (result) => {
            this.donationListSuccess(result);
          },
          error: (err) => {
            this.utilitiesService.showSpinner(false);
          },
        });
    } else {
      this.filterDonations('recent');
    }
  }

  public closePopup() {
    this.donationListPopUp = false;
  }

  private donationListSuccess(result: any) {
    this.utilitiesService.showSpinner(false);
    this.donationList = result?.donations;
    this.donationListFetched = true;
    this.filterDonations('recent');
  }

  public filterDonations(type: 'recent' | 'top') {
    this.activeFilter = type;
    if (type === 'recent') {
      this.currentDonationList = this.donationList.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    } else if (type === 'top') {
      this.currentDonationList = this.donationList.sort(
        (a, b) => b.amount - a.amount
      );
    }
  }

  public getDonationTime(createdAt: string): string {
    const donationDate = new Date(createdAt);
    const currentDate = new Date();
    const timeDiffInMs = currentDate.getTime() - donationDate.getTime();
    const hours = Math.floor(timeDiffInMs / (1000 * 60 * 60));
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''}`;
    } else {
      return `${hours + 1} hour${hours > 1 ? 's' : ''}`;
    }
  }

  public navigateToCampaignDonation(campaign: any): void {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
    });
    this.router.navigate(['/donate-to-campaign'], {
      queryParams: { data: encryptedParams },
    });
  }
}
