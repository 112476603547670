export class SignupBodyModel {
  name: string = null;
  company_name: string = null;
  company_designation: string = null;
  address: string = null;
  email: string = null;
  contact_number: string = null;
  password: string = null;
  profile_img: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}