import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import {
  ApprovalCategoryEnum,
  ApprovalStatusEnum,
} from '../enum/approval-category.enum';
import { ApprovalService } from '../services/approval/approval.service';
import { CampaignDonationModel } from './campaign-donation.model';
import { NgoCampaignModel } from './ngo-campaign.model';

export class ApprovalModel {
  ngo_id: string = null;
  project_id: string = null;
  approval_category_id: string = null;
  approval_category: ApprovalCategoryEnum = null;
  comments: string = null;
  attribute: string = null;
  approval_requested_by: string = LocalStorageService.getSessionUserStatic().id;

  constructor(category: ApprovalCategoryEnum) {
    this.approval_category = category;
    this.approval_category_id = ApprovalService.approval_category.get(category);
  }
}

export class CampaignFeaturedAttributeModel {
  id: string = null;
  title: string = null;
  subtitle: string = null;
  ngo_name: string = null;
  ngo_logo_url: string =
    LocalStorageService.getCurrentSelectedNgoStatic().logo_url;
  cover_img_vdo: string = null;
  start_date: Date = null;
  end_date: Date = null;
  fundraising_target: number = null;
  total_fund_raised: number = null;
  organizer_name: string = LocalStorageService.getSessionUserStatic().name;
  organizer_contact: string =
    LocalStorageService.getSessionUserStatic().contact_number;
  organizer_profile_img: string =
    LocalStorageService.getSessionUserStatic()?.profile_img || null;

  constructor(obj?: CampaignDonationModel | NgoCampaignModel) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
      this.cover_img_vdo = this.cover_img_vdo
        ? JSON.parse(this.cover_img_vdo)?.url
        : this.cover_img_vdo;
      this.ngo_logo_url = this.ngo_logo_url
        ? JSON.parse(this.ngo_logo_url)?.url
        : this.ngo_logo_url;
    }
  }
}

export class ApprovalUpdateModel {
  status: ApprovalStatusEnum = ApprovalStatusEnum.ACCEPTED;
  comments: string = null;

  constructor() {}
}
