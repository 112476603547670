import { Injectable } from '@angular/core';
import { UrlEnum } from '../../enum/url.enum';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private fileUploadApi = `${UrlEnum.SHOHAY_FILE}/aws-bucket-files`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public uploadFiles(files: File[] | null): Observable<any> {
    if (files) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }
      return this.httpRequestService
        .post(`${this.fileUploadApi}/upload/multiple`, formData)
        .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
    } else {
      return of(null);
    }
  }

  public uploadSingleFile(file: File | null): Observable<any> {
    if (file) {
      const formData = new FormData();
      formData.append('files', file);
      return this.httpRequestService
        .post(`${this.fileUploadApi}/upload/multiple`, formData)
        .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
    } else {
      return of(null);
    }
  }

  public deleteFile(fileId: string): Observable<any> {
    return this.httpRequestService
      .delete(`${this.fileUploadApi}/delete/${fileId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteFileMultiple(fileIds: string[]): Observable<any> {
    return this.httpRequestService
      .delete(`${this.fileUploadApi}/delete-multiple?ids=` + fileIds)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public changeFile(fileId: string, file): Observable<any> {
    return this.httpRequestService
      .patch(`${this.fileUploadApi}/update/${fileId}`, file)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
