<div class="top_div">
  <div class="main_div">
    @if(!popup){
    <div class="title_full_div">
      <div class="title_div">
        <div class="title_privacy">Pricing Policy</div>
        <div class="sub_title">Fair Prices, Transparent Values.</div>
      </div>
    </div>
    }
    <div class="white_div_container">
      <div class="white_div">
        <div class="policy_1">
          <div class="title">
            <span class="last-update">Last Updated:</span> October 24, 2024
          </div>
          <div class="description">
            At Shohay, we prioritize transparency in our operations and platform
            maintenance. To support NGOs and facilitate effective donor
            contributions, we offer various services, each with distinct pricing
            and fee structures.
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Special Pricing during Beta rollout</div>
          <div class="terms">
            Shohay offers special pricing during limited beta rollout periods
            for each of the following services, which may include free access
            promotions. Please stay tuned to our site for notifications about
            new promotions.
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Cloud Services for NGOs</div>
          <div class="definition_sub">
            Shohay offers the following types of Cloud Services:
          </div>
          <div class="terms">
            <ul>
              <li>Project Management</li>
              <li>Forms and Surveys for Field Operations</li>
              <li>Reports with Multi-tiered access filtering</li>
              <li>Fundraising Campaign Management</li>
              <li>Donor Management</li>
            </ul>
            <div class="definition_sub">
              Each service has base-level and advanced packages for complex
              processing. Pricing depends on the package and the volume of
              transactions to be processed. For more details, please contact us
              at <span class="shohay_email">sales&#64;shohay.net.</span>
            </div>
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Cloud Services for Project Donors</div>
          <div class="terms">
            Shohay can manage NGO and Non-profit services on behalf of Project
            Donors. This includes sourcing the right NGOs for desired projects
            and ensuring operational effectiveness through regular evaluation
            reports and alerts for failing KPIs. For pricing details, please
            contact us at sales&#64;shohay.net.
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Platform Fee for Donors</div>
          <div class="definition_sub">
            Shohay’s principle is to encourage donations. Therefore, we add a
            minimal donation processing fee for donations processed through the
            platform. Donors have the option to pay an additional nominal fee
            directly, or the amount can be deducted from the donation. 
          </div>
          <div class="terms">
            <ul>
              <li>In-country donations: 1% processing fee</li>
              <li>Foreign donations: 2% processing fee</li>
            </ul>
          </div>
          <div class="definition_sub">
            These fees are in addition to credit card processor fees. NGOs may
            choose to incur these fees themselves for their donation campaigns.
          </div>
          <div class="definition_sub left">
            <span class="highlights">Example:</span> If you donate BDT 1,000 in
            Bangladesh, Shohay may deduct BDT 10 (1%) and the BKash online
            processor may charge another 2%. The NGO will receive BDT 970.
            Alternatively, you may decide to pay an additional BDT 30, so the
            recipient receives the full amount of BDT 1,000.
          </div>
          <div class="definiton left">What Does the Fee Cover?</div>
          <div class="definition_sub left">
            The platform fee helps cover a range of operational costs that allow
            Shohay to continue supporting NGOs and Non-profits, ensuring a
            seamless experience for both donors and NGOs. Specifically, this fee
            helps us cover:
          </div>
          <div class="terms left">
            <ul>
              <li>
                <span class="highlights">Platform Maintenance:: </span> Ensuring
                the Shohay platform remains functional, user-friendly, and
                up-to-date with the latest features.
              </li>
              <li>
                <span class="highlights">Security Measures: </span> You will
                receive an email confirmation of your donation.
              </li>
              <li>
                <span class="highlights">Transaction Processing: </span>
                Handling costs associated with integrating third-party payment
                gateways and ensuring smooth transactions
              </li>
              <li>
                <span class="highlights">Support Services: </span>Offering
                customer support to help donors and NGOs with any issues that
                may arise.
              </li>
            </ul>
          </div>
          <div class="definiton left">Intellectual Property</div>
          <div class="definition_sub left">
            The Platform and all original content, including text, graphics,
            logos, and software, are owned by Shohay and protected by
            international copyright, trademark, and other intellectual property
            laws. You are not authorized to use Shohay’s content without
            permission.
          </div>
          <div class="definiton left">Additional Fees</div>
          <div class="definition_sub left">
            Please note that fees charged by third-party payment gateways or
            financial institutions are separate from Shohay’s platform fee and
            will be clearly displayed during the donation process if applicable.
          </div>

          <div class="definiton left">Transparency for NGOs and Donors</div>
          <div class="definition_sub left">
            Donors will always see the fee details before completing a
            transaction. NGOs using our platform should account for our fee
            structure in their fundraising campaigns. This ensures that all
            parties are informed of how much will be deducted from their
            donation.
          </div>
        </div>

        <div class="policy_2">
          <div class="definiton">Updates to This Pricing Policy</div>
          <div class="terms">
            Shohay may update this Pricing Policy from time to time. We will
            notify you through the platform or via email if we make significant
            changes. Your continued use of the platform following any changes to
            the policy constitutes your acceptance of the updated terms.
          </div>
        </div>

        <div class="policy_1">
          <div class="definiton">Contact Us</div>
          <div class="description_">
            <div class="description">
              If you have any questions or concerns regarding this Privacy
              Policy, please feel free to reach out to us:
            </div>
            <div>
              <span class="highlights_ block">Shohay Support Team</span>
              <div>
                <span class="highlights_">Email: </span>
                <span class="shohay_email">support&#64;shohay.net</span>
              </div>
              <span class="highlights_">Address:</span> 53 Purana Paltan, Dhaka,
              Bangladesh
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
