export class AnnouncementModel {
  ngo_id: string = null;
  project_id: string = null;
  campaign_id: string = null;
  title: string = null;
  body: string = null;
  announcement_date: Date = new Date();

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}