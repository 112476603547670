@if(isCommentHasData){
<div class="root">
  <div class="news-container">
    <h1 class="stay-updated-with">Donor Experience</h1>
    <div class="empowering-ngos-in">
      View words of support from donors and pictures or videos of their donation
      experience
    </div>
  </div>
  <section class="news-cards">
    @if(showMoreButton){
    <div *ngFor="let comment of commentsFiltering" class="cards">
      <!-- <div
        class="rectangle-parent"
        [style]="'background-image: url(' + imgsrc + ');'"
      ></div> -->

      <div class="main_body">
        <div class="profile_container">
          <div class="profile">
            <img
              class="img_profile"
              src="../../../../../assets/images/icon/profile_icon_gray.png"
              alt="pro pic"
              loading="lazy"
            />
          </div>

          <div class="name_donation">
            <div class="user_name">
              {{ comment.isAnonymous ? "Anonymous" : comment.name }}
            </div>
            <div class="donation_time">
              <div class="donation_amount">৳ {{ comment.amount }}</div>
              <div class="amount_time">{{ comment.date }}</div>
            </div>
          </div>
        </div>

        <div class="body_comment">
          {{ comment.comment }}
        </div>

        <div *ngIf="comment.replies.length > 0" class="reply_part">
          <div *ngFor="let reply of comment.replies" class="profile_container_">
            <div class="profile_">
              <img
                class="img_profile_"
                [src]="
                  reply.isAnonymous
                    ? '../../../../../assets/images/icon/profile_icon_gray.png'
                    : '../../../../../assets/images/icon/profile_icon_gray.png'
                "
                alt="pro pic"
                loading="lazy"
              />
            </div>

            <div class="name_donation_">
              <div class="name_organizer">
                <div class="user_name">
                  {{ reply.isAnonymous ? "Anonymous" : reply.name }}
                </div>
                <div class="organizer">Organizer</div>
              </div>
              <div class="donation_time">
                <div class="amount_time">{{ reply.date }}</div>
              </div>
              <div class="body_comment_">
                {{ reply.comment }}
              </div>
            </div>
          </div>
        </div>
        <div class="reactions">
          <img
            src="../../../../../../assets/images/icon/reactions_like_love.svg"
            alt=""
          />
          <span class="mt-1"> 36</span>
          <img
            src="../../../../../../assets/images/icon/Frame 39614.svg"
            alt=""
          />
          <img
            src="../../../../../../assets/images/icon/Frame 39634.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    }@else {
    <div *ngFor="let comment of comment_reply" class="cards">
      <!-- <div
        class="rectangle-parent"
        [style]="'background-image: url(' + imgsrc + ');'"
      ></div> -->

      <div class="main_body">
        <div class="profile_container">
          <div class="profile">
            <img
              class="img_profile"
              src="../../../../../assets/images/icon/profile_icon_gray.png"
              alt="pro pic"
              loading="lazy"
            />
          </div>

          <div class="name_donation">
            <div class="user_name">
              {{ comment.isAnonymous ? "Anonymous" : comment.name }}
            </div>
            <div class="donation_time">
              <div class="donation_amount">
                ৳ {{ comment.name === "Anonymous" ? "Hidden" : comment.amount }}
              </div>
              <div class="amount_time">{{ comment.date }}</div>
            </div>
          </div>
        </div>

        <div class="body_comment">
          {{ comment.comment }}
        </div>

        <div *ngIf="comment.replies.length > 0" class="reply_part">
          <div *ngFor="let reply of comment.replies" class="profile_container_">
            <div class="profile_">
              <img
                class="img_profile_"
                [src]="
                  reply.isAnonymous
                    ? '../../../../../assets/images/icon/profile_icon_gray.png'
                    : '../../../../../assets/images/icon/profile_icon_gray.png'
                "
                alt="pro pic"
                loading="lazy"
              />
            </div>

            <div class="name_donation_">
              <div class="name_organizer">
                <div class="user_name">
                  {{ reply.isAnonymous ? "Anonymous" : reply.name }}
                </div>
                <div class="organizer">Organizer</div>
              </div>
              <div class="donation_time">
                <div class="amount_time">{{ reply.date }}</div>
              </div>
              <div class="body_comment_">
                {{ reply.comment }}
              </div>
            </div>
          </div>
        </div>
        <div class="reactions">
          <img
            src="../../../../../../assets/images/icon/reactions_like_love.svg"
            alt=""
          />
          <span class="mt-1"> 36</span>
          <img
            src="../../../../../../assets/images/icon/Frame 39614.svg"
            alt=""
          />
          <img
            src="../../../../../../assets/images/icon/Frame 39634.svg"
            alt=""
          />
        </div>
      </div>
    </div>
    }
  </section>
  <div *ngIf="showMoreButton" class="load_comments_container">
    <dx-button
      class="blue-button-md"
      (click)="loadMoreComments()"
      [disabled]="loadMoreDisabled"
    >
      Load more
    </dx-button>
  </div>
</div>
}
