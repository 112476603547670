<div class="d-flex w-100 flex-column">
  <div class="d-flex justify-content-between w-100 mb-4">
    <div class="d-flex gap-2 align-items-center">
      <div class="font_24">Action:</div>
      <dx-drop-down-button
        class="action_button"
        [text]="actionTitle"
        [dropDownOptions]="{ width: 130 }"
        displayExpr="text"
        keyExpr="text"
        [useItemTextAsTitle]="true"
        [items]="[
          { id: 'delete', text: 'Delete' },
          { id: 'share', text: 'Share' },
          { id: 'download', text: 'Download' }
        ]"
        (onItemClick)="changeActionTitle($event)"
      ></dx-drop-down-button
      ><button class="blue-button-md" (click)="action()">Apply</button>
    </div>
    <button class="blue-button-md">
      <img
        style="margin-bottom: 3px; margin-right: 4px"
        src="/assets/images/icon/add_square_white_icon.svg"
      />
      Add New Asset
    </button>
  </div>

  <div class="d-flex row">
    <div
      class="col-sm-12 col-lg-6 col-xl-4 mb-4"
      *ngFor="let isSelected of imageList; let g_i = index"
    >
      <div class="select_image">
        <div class="image">
          <!-- [ngStyle]="{
            'background-image': 'url(src/assets/images/flood_2024_1.PNG)'
          }" -->
          <!-- <dx-check-box
            iconSize="32"
            [ngModel]="isSelected"
            (onValueChanged)="selectImage($event, g_i)"
            cli
          ></dx-check-box> -->
          <input
            style="width: 32px; height: 32px"
            type="checkbox"
            [ngModel]="imageList[g_i]"
            id="checkbox-{{ g_i }}"
            (click)="selectImage($event, g_i)"
          />
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <div class="font_22">Aid-Appeal.png</div>
            <div class="png"><span>PNG</span> - <span>1500x800</span></div>
          </div>
          <div class="image_video">IMAGE</div>
        </div>
      </div>
    </div>
  </div>
</div>
