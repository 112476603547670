import { Injectable } from '@angular/core';
import { UtilitiesService } from '../utilities/utilities.service';
import { HttpRequestService } from '../http-request/http-request.service';
import { NgoCampaignModel } from '../../model/ngo-campaign.model';
import { map, Observable } from 'rxjs';
import { UrlEnum } from '../../enum/url.enum';
import { HttpParams } from '@angular/common/http';
import { AnnouncementModel } from '../../model/announcement.model';

@Injectable({
  providedIn: 'root',
})
export class NgoCampaignService {
  private ngoCampaignUrl = `${UrlEnum.SHOHAY_NGO}/ngo-donation/campaign`;
  private campaignAnnouncement = `${UrlEnum.SHOHAY_CAMPAIGN}/ngo-donation/announcement`;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public getAllNgoDonationCampaign(
    page: number,
    size: number
  ): Observable<any> {
    return this.httpRequestService
      .get(`${this.ngoCampaignUrl}/get-all?page=${page}&size=${size}`, {
        skipAuth: true,
      })
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => {
          x.data.forEach((y: any) => {
            y.cover_img_vdo = JSON.parse(y.cover_img_vdo);
          });
          return x;
        })
      );
  }

  public getAllNgoDonationCampaignTitles(): Observable<any> {
    return this.httpRequestService
      .get(`${this.ngoCampaignUrl}/get-all-titles`, { skipAuth: true })
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) =>
          x.map((campaign: any) => ({
            ...campaign,
            title_subtitle: `${campaign.title.toLowerCase()}_${campaign.subtitle.toLowerCase()}`,
          }))
        )
      );
  }

  public getFeaturedNgoDonationCampaign(): Observable<any> {
    return this.httpRequestService
      .get(`${this.ngoCampaignUrl}/get-all-featured-campaign`, {
        skipAuth: true,
      })
      .pipe(
        map((response: any) => this.utilitiesService.responseHandler(response)),
        map((campaigns: any[]) =>
          campaigns.map((campaign: any) => ({
            ...campaign,
            cover_img_vdo: JSON.parse(campaign.cover_img_vdo),
          }))
        ),
        map((campaigns: any[]) =>
          campaigns.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public getNgoDonationCampaignsByNgo(ngoId: string): Observable<any> {
    const params = new HttpParams().set('ngo_id', ngoId);
    return this.utilitiesService
      .responseHandlerWithAlert(
        this.httpRequestService.get(
          `${this.ngoCampaignUrl}/get-by-ngo/${ngoId}`,
          {
            params,
          }
        )
      )
      .pipe(
        map((campaigns: any[]) =>
          campaigns.map((campaign: any) => ({
            ...campaign,
            cover_img_vdo: JSON.parse(campaign.cover_img_vdo),
          }))
        ),
        map((x: any[]) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public getNgoDonationCampaignsByProject(
    ngoId: string,
    projectId: string
  ): Observable<any> {
    const params = new HttpParams()
      .set('ngo_id', ngoId)
      .set('project_id', projectId);
    return this.httpRequestService
      .get(`${this.ngoCampaignUrl}/get-by-project`, {
        params,
      })
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => {
          return {
            ...x,
            ngo_donation_campaign: x.ngo_donation_campaign.map(
              (campaign: any) => ({
                ...campaign,
                cover_img_vdo: JSON.parse(campaign.cover_img_vdo),
              })
            ),
          };
        }),
        map((x: any) => {
          x.ngo_donation_campaign.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          );
          return x;
        })
      );
  }

  public getNgoCampaignsByIds(ids: string[]): Observable<any> {
    return this.httpRequestService
      .get(`${this.ngoCampaignUrl}/get-by-multiple-id?campaign_ids=${ids}`, {
        skipAuth: true,
      })
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) => {
          x.forEach((y: any) => {
            y.cover_img_vdo = JSON.parse(y.cover_img_vdo);
          });
          return x;
        })
      );
  }

  public createNgoCampaign(campaignBody: NgoCampaignModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.ngoCampaignUrl}/create`, campaignBody)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public startCampaign(
    ngoId: string,
    projectId: string,
    id: string,
    startDate: string
  ): Observable<any> {
    const url = `${this.ngoCampaignUrl}/start-campaign/ngos/${ngoId}/projects/${projectId}/campaigns/${id}`;
    return this.httpRequestService
      .patch(url, {
        start_date: startDate,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public stopCampaign(
    ngoId: string,
    projectId: string,
    id: string,
    endDate: string
  ): Observable<any> {
    const url = `${this.ngoCampaignUrl}/stop-campaign/ngos/${ngoId}/projects/${projectId}/campaigns/${id}`;
    return this.httpRequestService
      .patch(url, {
        end_date: endDate,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getCampaignAnnouncement(campaign_id: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.campaignAnnouncement}/get-by-campaign/${campaign_id}`, {
        skipAuth: true,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createCampaignAnnouncement(body: AnnouncementModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.campaignAnnouncement}/create`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateCampaignAnnouncement(body: any, id: string): Observable<any> {
    return this.httpRequestService
      .patch(`${this.campaignAnnouncement}/update/${id}`, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteCampaignAnnouncement(id: string): Observable<any> {
    return this.httpRequestService
      .delete(`${this.campaignAnnouncement}/delete-by-id/${id}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteCampaign(id: string): Observable<any> {
    return this.httpRequestService
      .delete(`${this.ngoCampaignUrl}/delete-by-id/${id}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public requestForFeaturedCampaign(campaignId: string): Observable<any> {
    return this.httpRequestService
      .post(
        `${this.ngoCampaignUrl}/request-for-featured-campaign/${campaignId}`,
        { is_requested: true }
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getNgoCampaignFeaturedRequest(): Observable<any> {
    return this.httpRequestService
      .get(`${this.ngoCampaignUrl}/get-all-featured-request`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public approveNgoCampaignFeaturedRequest(
    campaignId: string,
    flag: boolean
  ): Observable<any> {
    return this.httpRequestService
      .post(`${this.ngoCampaignUrl}/update-featured-campaign/${campaignId}`, {
        is_featured: flag,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
