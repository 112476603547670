import { UtilitiesService } from './../../../../shared/services/utilities/utilities.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FundraiserService } from './../../../../shared/services/fundraiser/fundraiser.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fundraisers-list',
  templateUrl: './fundraisers-list.component.html',
  styleUrl: './fundraisers-list.component.scss',
})
export class FundraisersListComponent implements OnInit {
  @Input() campaign_id: string;

  public fundraiserTeams: any[] = [];
  public selectedTeam: any = null;
  public selectedTeamLogo: any;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly fundraiserService: FundraiserService
  ) {}

  ngOnInit(): void {
    this.getAllFundraisersTeams();
  }

  private getAllFundraisersTeams(): void {
    this.spinner.show();
    this.fundraiserService
      .getAllTeamsWithFundraisers(this.campaign_id)
      .subscribe({
        next: (result: any) => {
          this.spinner.hide();
          if (result?.length > 0) {
            this.fundraiserTeams = this.modifyTeamsList(result).sort(
              (a, b) => a.fund_raised - b.fund_raised
            );
            this.selectTeam(this.fundraiserTeams[0]);
          }
        },
        error: (error: any) => {
          this.spinner.hide();
          this.utilitiesService.showSwalWithToast(
            'Error',
            error?.error?.message,
            'error'
          );
        },
      });
  }

  private modifyTeamsList(result: any[]): any[] {
    result.forEach((item: any) => {
      item.team_logo = item.team_logo ? JSON.parse(item.team_logo) : null;
      item.members.forEach((member: any) => {
        member.profile_img = item.profile_img
          ? JSON.parse(item.profile_img)
          : null;
      });
    });
    return result;
  }

  public selectTeam(team: any): void {
    this.selectedTeam = team;
    this.selectedTeam.members.sort((a, b) => a.updated_at - b.updated_at);
  }
}
