<div class="donor-container">
  @if(isCampaignHasData){
  <div class="abcd text-start mt-4 mb-4 w-100">
    <div class="recent_donation">Recent Donation</div>
    <img
      loading="lazy"
      src="../../../../../assets/images/icon/trending_up_icon.svg"
      alt="Donor"
      title="donor"
      class="for_donor"
    />
    <span class="font_16 recent_donated_text"
      >{{ campaign.total_donors > 0 ? campaign.total_donors : 0 }} people just
      donated</span
    >
  </div>
  <!-- (onSelectionChanged)="onTabChange($event)" -->
  <div class="donor_list_container">
    <dx-tab-panel
      style="box-shadow: none"
      class="all_tabs"
      [swipeEnabled]="true"
    >
      <dxi-item title="Donation"> </dxi-item>
      <dxi-item title="Pledging"> </dxi-item>
    </dx-tab-panel>
    <div class="donor_list">
      <div class="d-flex gap-2">
        <div class="donor_icon_div">
          <img
            src="../../../../../assets/images/icon/donor_icon_blue.png"
            alt="Donor"
            title="donor"
          />
        </div>
        <div class="d-flex gap-2 flex-column">
          <span class="font_18"
            >{{ donationSummary.recent_donation.name }}
          </span>
          <span class="font_16">
            ৳{{
              donationSummary.recent_donation.amount === (0 | number)
                ? "Hidden"
                : (donationSummary.recent_donation.amount | number)
            }}
            <span class="font_14" style="text-decoration: underline"
              >Recent Donation</span
            >
          </span>
        </div>
      </div>
    </div>

    <div class="donor_list">
      <div class="d-flex gap-2">
        <div class="donor_icon_div">
          <img
            src="../../../../../assets/images/icon/donor_icon_blue.png"
            alt="Donor"
            title="donor"
          />
        </div>
        <div class="d-flex gap-2 flex-column">
          <span class="font_18">{{ donationSummary.top_donation.name }} </span>
          <span class="font_16">
            ৳{{
              donationSummary.top_donation.amount === (0 | number)
                ? "Hidden"
                : (donationSummary.top_donation.amount | number)
            }}
            <span class="font_14" style="text-decoration: underline"
              >Top Donation</span
            >
          </span>
        </div>
      </div>
    </div>
    <div class="donor_list">
      <div class="d-flex gap-2">
        <div class="donor_icon_div">
          <img
            src="../../../../../assets/images/icon/donor_icon_blue.png"
            alt="Donor"
            title="donor"
          />
        </div>
        <div class="d-flex gap-2 flex-column">
          <span class="font_18"
            >{{ donationSummary.first_donation.name }}
          </span>
          <span class="font_16">
            ৳{{
              donationSummary.first_donation.amount === (0 | number)
                ? "Hidden"
                : (donationSummary.first_donation.amount | number)
            }}
            <span class="font_14" style="text-decoration: underline"
              >First Donation</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="white-button-md see-all-btn" (click)="seeAll()">See All</div>
  }@else {
  <div class="font_24">No Donation History is available</div>
  }
</div>

<dx-popup
  [maxWidth]="721"
  [maxHeight]="802"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="donationListPopUp"
  [showTitle]="false"
>
  <div class="donation_list_popup">
    <div class="all_donation_container">
      <div class="cross_button_conatiner">
        <div class="cross_button_div">
          <button class="cross_button" (click)="closePopup()">
            <img
              src="../../../../../assets/images/icon/cross_icon.svg"
              alt="cross"
              loading="lazy"
              title="close"
            />
          </button>
        </div>
        <div class="donation_list_text">
          Donations List ( {{ donationList?.length }})
        </div>
      </div>
      <div class="button_container">
        <button
          (click)="filterDonations('recent')"
          [class.most_recent_button]="activeFilter === 'recent'"
          [class.white-button-md]="activeFilter !== 'recent'"
          style="width: 48%"
        >
          Most Recent
        </button>
        <button
          (click)="filterDonations('top')"
          [class.most_recent_button]="activeFilter === 'top'"
          [class.white-button-md]="activeFilter !== 'top'"
          style="width: 48%"
        >
          Top Donations
        </button>
      </div>
    </div>
    <dx-scroll-view width="100%" height="70%">
      <div class="all_donation_container">
        <div
          class="donar_container"
          *ngFor="let donor of currentDonationList; let i = index"
        >
          <div class="donor_list_">
            <div class="donor_icon_div">
              <img
                src="../../../../../assets/images/icon/donor_icon_blue.png"
                alt="Donor"
                title="donor"
              />
            </div>
            <div class="d-flex gap-2 flex-column">
              <span class="font_18">
                {{ donor.is_hidden_name ? "Anonymous" : donor.name }}
              </span>
              <span class="font_16">
                ৳{{
                  donor.is_hidden_name_and_amount
                    ? "Hidden"
                    : (donor.amount | number)
                }}
                <span class="hours"
                  >in {{ getDonationTime(donor?.created_at) }}</span
                >
              </span>
            </div>
          </div>
          <div *ngIf="(i + 1) % 3 === 0" class="join_list">
            <div class="joint_list_text">Join this list</div>
            <button
              class="donate_now_button"
              (click)="navigateToCampaignDonation(campaign)"
            >
              <!-- [routerLink]="['/donate-to-campaign']"
                [queryParams]="{
                  ngo_id: campaign?.ngo_id,
                  project_id: campaign?.project_id,
                  id: campaign?.id
                }" -->
              Donate Now
            </button>
          </div>
        </div>
      </div>
    </dx-scroll-view>
    <div class="donation_button">
      <button
        class="blue-button-lg w-100"
        (click)="navigateToCampaignDonation(campaign)"
      >
        <!-- [routerLink]="['/donate-to-campaign']"
          [queryParams]="{
            ngo_id: campaign?.ngo_id,
            project_id: campaign?.project_id,
            id: campaign?.id
          }" -->
        Donate Now
      </button>
    </div>
  </div>
</dx-popup>
